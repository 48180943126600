<template>
	<div class="team">
		<div class="banner">
			<div class="font1">{{ $t('team.font1') }}</div>
			<div class="font2">
				{{ $t('team.font2') }}
			</div>
		</div>
		<div class="body">
			<div class="item-box">
				<div class="item">
					<div class="img-box">
						<img src="../assets/team/1.png" alt="" />
					</div>
					<div class="content">
						<div class="name">
							{{ $t('team.item1.name') }}
						</div>
						<div class="title">
							{{ $t('team.item1.title1') }}
							<br />
							{{ $t('team.item1.title2') }}
						</div>
					</div>
					<div class="font">
						{{ $t('team.item1.font') }}
					</div>
				</div>
				<div class="item">
					<div class="img-box">
						<img src="../assets/team/2.png" alt="" />
					</div>
					<div class="content">
						<div class="name">
							{{ $t('team.item2.name') }}
						</div>
						<div class="title">
							{{ $t('team.item2.title1') }}
							<br />
							{{ $t('team.item2.title2') }}
						</div>
					</div>
					<div class="font">
						{{ $t('team.item2.font') }}
					</div>
				</div>
				<!-- <div class="item">
					<div class="img-box">
						<img src="../assets/team/3.png" alt="" />
					</div>
					<div class="content">
						<div class="name">
							{{ $t('team.item3.name') }}
						</div>
						<div class="title">
							{{ $t('team.item3.title1') }}
							<br />
							{{ $t('team.item3.title2') }}
						</div>
					</div>
					<div class="font">
						{{ $t('team.item3.font') }}
					</div>
				</div>
				<div class="item">
					<div class="img-box">
						<img src="../assets/team/4.png" alt="" />
					</div>
					<div class="content">
						<div class="name">
							{{ $t('team.item4.name') }}
						</div>
						<div class="title">
							{{ $t('team.item4.title1') }}
							<br />
							{{ $t('team.item4.title2') }}
						</div>
					</div>
					<div class="font">
						{{ $t('team.item4.font') }}
					</div>
				</div> -->
				<div class="item">
					<div class="img-box">
						<img src="../assets/team/5.png" alt="" />
					</div>
					<div class="content">
						<div class="name">
							{{ $t('team.item5.name') }}
						</div>
						<div class="title">
							{{ $t('team.item5.title1') }}
							<br />
							{{ $t('team.item5.title2') }}
						</div>
					</div>
					<div class="font">
						{{ $t('team.item5.font') }}
					</div>
				</div>
				<div class="item">
					<div class="img-box">
						<img src="../assets/team/6.png" alt="" />
					</div>
					<div class="content">
						<div class="name">
							{{ $t('team.item6.name') }}
						</div>
						<div class="title">
							{{ $t('team.item6.title1') }}
							<br />
							{{ $t('team.item6.title2') }}
						</div>
					</div>
					<div class="font">
						{{ $t('team.item6.font') }}
					</div>
				</div>
				<!-- <div class="item">
					<div class="img-box">
						<img src="../assets/team/7.png" alt="" />
					</div>
					<div class="content">
						<div class="name">
							{{ $t('team.item7.name') }}
						</div>
						<div class="title">
							{{ $t('team.item7.title1') }}
							<br />
							{{ $t('team.item7.title2') }}
						</div>
					</div>
					<div class="font">
						{{ $t('team.item7.font') }}
					</div>
				</div> -->
				<div class="item">
					<div class="img-box">
						<img src="../assets/team/8.png" alt="" />
					</div>
					<div class="content">
						<div class="name">
							{{ $t('team.item8.name') }}
						</div>
						<div class="title">
							{{ $t('team.item8.title1') }}
							<br />
							{{ $t('team.item8.title2') }}
						</div>
						<div class="font">
							{{ $t('team.item8.font') }}
						</div>
					</div>
				</div>
				<div class="item">
					<div class="img-box">
						<img src="../assets/team/9.png" alt="" />
					</div>
					<div class="content">
						<div class="name">
							{{ $t('team.item9.name') }}
						</div>
						<div class="title">
							{{ $t('team.item9.title1') }}
							<br />
							{{ $t('team.item9.title2') }}
						</div>
						<div class="font">
							{{ $t('team.item9.font') }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref } from 'vue';

const blogList = ref([]);
const go = (item) => {
	window.open(item.linkUrl, '_blank');
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 768px) {
	.team {
		width: 100%;
		background: rgba(0, 0, 0, 1);
		color: #ffffff;
		.banner {
			width: 100%;
			height: 456px;
			background: url('../assets/h5team/banner.png');
			background-size: 100% 456px;
			display: flex;
			align-items: center;
			flex-direction: column;
			.font1 {
				margin-top: 120px;
				color: #f5f5f5;
				font-size: 36px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}
			.font2 {
				color: #fff;
				margin-top: 20px;
				width: 292px;
				text-align: center;
				font-family: Lexend;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
		.body {
			width: 100%;
			height: 2000px;
			background: url('../assets/h5team/back.png');
			background-size: 100% 2000px;
			padding: 9px 41px 20px;
			// display: flex;
			// // justify-content: center;
			.item-box {
				width: 100%;
				display: flex;
				flex-direction: column;
				// flex-wrap: wrap;
				// justify-content: space-between;
				.item {
					width: 293px;
					border-bottom: 1px solid rgba(172, 172, 172, 1);
					color: rgba(255, 255, 255, 1);
					margin-bottom: 40px;
					display: flex;
					flex-wrap: wrap;
					padding-bottom: 50px;
					.img-box {
						width: 120px;
						height: 120px;
						border-radius: 20px;
						margin-bottom: 20px;
						margin-right: 30px;
						img {
							width: 120px;
							height: 120px;
						}
					}
					.title {
						width: 143px;
						height: auto;
						font-size: 15px;
						font-weight: 500;
						color: rgba(255, 255, 255, 1);
						margin: 16px 0 0;
						// overflow: hidden; //超出的文本隐藏
						// text-overflow: ellipsis; //溢出用省略号显示
						// white-space: nowrap; //溢出不换行
					}
					.name {
						width: 143px;
						height: auto;
						font-size: 20px;
						font-weight: 600;
						color: rgba(253, 170, 51, 1);
						// overflow: hidden;
						// text-overflow: ellipsis;
						// display: -webkit-box; //作为弹性伸缩盒子模型显示。
						// -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
						// -webkit-line-clamp: 1; //显示的行
					}
					.font {
						width: 100%;
						height: auto;
						font-size: 12px;
						font-weight: 400;
					}
				}
				// .item:nth-child(9) {
				// 	height: auto;
				// 	border: 0;
				// }
			}
		}
	}
}
/* 当前屏幕大于768px 应用媒体查询里面的样式 */
@media screen and (min-width: 768px) {
	.team {
		width: 100%;
		// overflow: auto;
		// height: calc(100vh - 80px);
		background: rgba(0, 0, 0, 1);
		color: #ffffff;
		.banner {
			width: 100%;
			height: 475px;
			background: url('../assets/team/banner.png');
			background-size: 1920px 475px;
			display: flex;
			align-items: center;
			flex-direction: column;
			.font1 {
				margin-top: 280px;
				width: 538px;
				color: #f5f5f5;
				font-size: 64px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}
			.font2 {
				color: #fff;
				margin-top: 30px;
				width: 1400px;
				text-align: center;
				font-family: Lexend;
				font-size: 22px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
		.body {
			width: 100%;
			height: 3073px;
			background: url('../assets/team/back.png');
			background-size: 1920px 3073px;
			margin-top: 75px;
			// padding: 75px 0 20px;
			display: flex;
			justify-content: center;
			.item-box {
				width: 1440px;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				.item {
					width: 681px;
					height: 800px;
					border-bottom: 1px solid rgba(172, 172, 172, 1);
					font-size: 24px;
					font-weight: 400;
					color: rgba(255, 255, 255, 1);
					// cursor: pointer;
					margin-bottom: 100px;
					.img-box {
						width: 334px;
						height: 334px;
						// padding: 15px;
						border-radius: 20px;
						border: 1px solid rgba(255, 255, 255, 0.65);
						margin-bottom: 20px;
						img {
							width: 304px;
							height: 304px;
							width: 334px;
							height: 334px;
						}
					}
					.title {
						width: 86%;
						font-size: 34px;
						font-weight: 600;
						color: rgba(255, 255, 255, 1);
						margin: 10px 0 30px;
						overflow: hidden; //超出的文本隐藏
						text-overflow: ellipsis; //溢出用省略号显示
						white-space: nowrap; //溢出不换行
					}
					.name {
						font-size: 50px;
						font-weight: 600;
						color: rgba(253, 170, 51, 1);
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box; //作为弹性伸缩盒子模型显示。
						-webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
						-webkit-line-clamp: 1; //显示的行
					}
				}
				.item:nth-child(9) {
					height: auto;
					border: 0;
				}
			}
		}
	}
}
</style>
